import * as React from 'react'
import Layout from '../../components/Layout/Layout'
import Banner from '../../components/Banner/Banner'
import Button from '../../components/Button/Button'
import Title from '../../components/Title/Title'
import Text from '../../components/Text/Text'
import Underline from '../../components/Underline/Underline'
import { Helmet } from 'react-helmet'

function SportsInjury() {
    return (
        <Layout>
            <Helmet>
                <title>Sports injury - Conditions we treat | Brixworth Osteopathic Clinic</title>
            </Helmet>
            <Title blue={true}>
                <h1>Sports Injury</h1>
            </Title>
            <section>
                <Text>
                    <Underline>
                        <h2>Osteopathy in Sport</h2>
                    </Underline>
                    <p>Taking part in sport or keeping fit can be rewarding, improve your health and reduce the risk of illnesses such as heart problems, strokes and cancer. It can also help maintain your weight and improve your self-esteem.</p>
                    <p>Adults between the age of 19 – 64 are advised to do at least 30 minutes of moderate intensity activity, 5 days per week. This can include cardiovascular activities like fast walking, cycling or aqua aerobics, as well as exercises to strengthen all the main muscle groups twice per week. Even if you are active regularly, you should also minimize the amount of time spent sitting for extended periods.</p>
                    <p>Sport has become a way of life for many in the UK, with participants ranging from elite athletes to those who just want to keep fit. But what happens if you get an injury?</p>
                    <p>Doing sport too often or too hard may result in injury. Failing to warm up properly beforehand, or warm down and stretch after exercise may also result in strains. Using inappropriate equipment or wearing the wrong footwear may likewise hinder your performance. An unresolved prior injury, which might not even be related to sport, can make it difficult even to get started.</p>
                </Text>
                <Text>
                    <Underline>
                        <h2>How Can You Help Yourself?</h2>
                    </Underline>
                    <ul>
                        <li>Begin your activity slowly and build up intensity, especially after an injury.</li>
                        <li>Drink plenty of water when you are thirsty, especially if exercising.</li>
                        <li>Exercise regularly, and try to vary the types of exercise you do for all over body fitness.</li>
                        <li>If you believe you have injured a limb; rest, ice, compression and elevation might help, but seek advice if you are worried.</li>
                        <li>Normal soft tissue healing time can take up to 12 weeks (if no other injuries occur). If you’re worried at all about an injury in the short, medium or long term it is worth seeking further advice.</li>
                    </ul>
                </Text>
                <Text>
                    <Underline>
                        <h2>How Can an Osteopath Help?</h2>
                    </Underline>
                    <p>It is common to feel some minor discomfort after training, as the body takes a little time to recover and adapt to the demands of your activities.</p>
                    <p>Soreness often resolves quickly by itself, but occasionally it may persist for more than a few days or make it difficult for you to continue your normal activities. In these instances you may want to seek some advice from an osteopath.</p>
                    <p>Osteopaths use a wide range of hands-on techniques. Treatment varies depending on your age, fitness and diagnosis, but usually focuses on stretching muscles and mobilising your joints – which may help to relieve your pain. Osteopaths may also adjust your joints using manipulation techniques. Occasionally when this happens there will be an audible ‘click’, this is perfectly normal. Research has shown that manipulations can have beneficial effects, especially for back pain, helping you to return to ordinary movement and activity. Your osteopath may also use strapping or taping which may help ease your pain.</p>
                    <p>As well as treating injuries, your osteopath may offer you advice about optimal nutrition or suggest a sport-specific diet.</p>
                    <p>Osteopaths are trained to determine if your sports related discomfort is due to a more serious underlying problem, or if it arises from a mechanical strain. It is natural to worry about what may be behind your symptoms, and it can be frustrating when pain stops you training.</p>
                    <p>Your osteopath will always check for more serious conditions as part of their routine examination, and will advise you if further action is required.</p>
                    <p>The good news is that although sports injuries are common, those who are active and have experience of following exercise routines may find they recover quicker and more easily from their injuries.</p>
                </Text>
            </section>
            <section style={{backgroundColor: 'var(--primary-800)', color: 'white'}}>
                <Banner>
                    <h2>Make an appointment with us.</h2>
                    <Button external={true} type="neutralOutline" to="https://eubookings.nookal.com/bookings/location/GGNJC">Book online today</Button>
                </Banner>
            </section>
        </Layout>
    )
}

export default SportsInjury